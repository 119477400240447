import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Input, { InputHeight, InputStyle } from '../Input';

interface IVerificationCodeInputProps {
  length: number;
  loading: boolean;
  onComplete: (code: string) => void;
  onChange: (code: string) => void;
  name: string;
  className?: string;
  placeholder?: string;
}

export default function VerificationCodeInput({
  length,
  loading,
  onComplete,
  onChange,
  name,
  className,
  placeholder = '',
  ...rest
}: IVerificationCodeInputProps) {
  const [code, setCode] = useState([...Array(length)].map(() => ''));
  const inputs = useRef<HTMLInputElement[]>([]);

  const processInput = (e: React.ChangeEvent<HTMLInputElement>, slot: number) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    onChange(newCode.join(''));
    if (slot !== length - 1) {
      inputs.current[slot + 1]?.focus();
    }
    if (newCode.every((num) => num !== '')) {
      onComplete(newCode.join(''));
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, slot: number) => {
    if (e.code === 'Backspace' && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = '';
      setCode(newCode);
      inputs.current[slot - 1]?.focus();
    } else if (e.code === 'ArrowRight' && !code[slot + 1]) {
      inputs.current[slot + 1]?.focus();
    } else if (e.code === 'ArrowLeft' && !code[slot - 1]) {
      inputs.current[slot - 1]?.focus();
    }
  };

  return (
    <div className="code-input">
      <div className="flex justify-around">
        {code.map((num, idx) => (
          <Input
            name={name}
            key={idx}
            type="text"
            inputMode="numeric"
            maxLength={1}
            className={twMerge(clsx('text-center text-title-2', className))}
            placeholder={placeholder}
            inputStyle={InputStyle.Primary}
            inputHeight={InputHeight.Medium}
            {...rest}
            value={num}
            autoFocus={!code[0].length && idx === 0}
            readOnly={loading}
            onChange={(e) => processInput(e, idx)}
            onKeyUp={(e) => onKeyUp(e, idx)}
            autoComplete="off"
            innerRef={(ref: HTMLInputElement) => {
              if (inputs.current.length < length) {
                inputs.current.push(ref);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
}
