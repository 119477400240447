import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import Loader from '../Loader';

export enum ButtonType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary',
  IconButton = 'IconButton',
  Tab = 'Tab',
  Link = 'Link',
}

interface IButtonProps {
  className?: string;
  children: any;
  type: ButtonType;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onSubmit?: React.FormEventHandler<HTMLButtonElement> | undefined;
  iconImage?: any;
  iconRight?: any;
  style?: React.CSSProperties | undefined;
  disabled?: boolean;
  buttonType?: 'submit' | 'button' | 'reset' | undefined;
  loading?: boolean;
  dataTip?: string | undefined | boolean;
  dataFor?: string;
  setShowTooltip?: (showTooltip: boolean) => void;
  id?: string;
}

export default function Button({
  children,
  className,
  type,
  onClick,
  onSubmit,
  iconImage,
  iconRight,
  style,
  disabled = false,
  buttonType = 'submit',
  loading,
  dataTip,
  dataFor,
  id,
  setShowTooltip,
}: IButtonProps): React.ReactElement {
  if (disabled) {
    type = ButtonType.Secondary;
  }

  const classes = twMerge(
    clsx('flex justify-center items-center px-6 h-14', {
      'bg-btn-secondary hover:bg-btn-overlay-primary text-light font-medium rounded-full': type === ButtonType.Primary,
      'bg-secondary text-primary font-medium rounded-full hover:bg-btn-overlay-secondary disabled:text-secondary disabled:border-primary disabled:border-1 disabled:bg-transparent': type === ButtonType.Secondary,
      'bg-btn-tertiary hover:bg-btn-overlay-secondary text-primary font-medium rounded-full': type === ButtonType.Tertiary,
      'bg-secondary text-primary font-medium h-8 w-11 rounded-sm': type === ButtonType.Tab,
      'rounded-full w-16 flex justify-center items-center': type === ButtonType.IconButton,
      'bg-transparent text-primary px-0 h-auto': type === ButtonType.Link,
    }),
    className
  );

  let mouseActions = {};

  if (setShowTooltip) {
    mouseActions = {
      onMouseEnter: () => setShowTooltip!(true),
      onMouseLeave: () => {
        setShowTooltip!(false);
        setTimeout(() => setShowTooltip!(true), 50);
      },
    };
  }

  return (
    <button
      className={classes}
      disabled={disabled || loading}
      onClick={onClick}
      onSubmit={onSubmit}
      style={style}
      type={buttonType}
      data-tip={dataTip}
      data-for={dataFor}
      id={id}
      {...mouseActions}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {iconImage && <img alt="icon" src={iconImage} className="mr-3" />}
          {children}
          {iconRight && <div className="ml-3">{iconRight}</div>}
        </>
      )}
    </button>
  );
}
