import LoadingScreen from '@/common/LoadingScreen';
import { useAuthUser } from '@/hooks/useAuthUser';
import OnboardingLayout from '@/layouts/OnboardingLayout';
import { firebaseAuth } from '@/lib/Firebase';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import EnterPhone from './EnterPhone';
import VerifyPhone from './VerifyPhone';

function Login<NextPageWithLayout>() {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [step, setStep] = useState<number>(0);
  const router = useRouter();
  const { isLoading, data: authUser } = useAuthUser(['authUser'], firebaseAuth);

  useEffect(() => {
    // If user is not logged in, return login component
    if (!isLoading && authUser) {
      router.replace('/wallet');
      return;
    }
  }, [isLoading]);

  const onChangePhone = (phone: string) => {
    setPhoneNumber(phone);
  };

  const onNext = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <EnterPhone onNext={onNext} phoneNumber={phoneNumber} onChangePhone={onChangePhone} />
        );
      default:
        return <VerifyPhone phoneNumber={phoneNumber} />;
    }
  };

  if (isLoading || authUser) {
    return <LoadingScreen />;
  }

  return <div className="w-full flex justify-center absolute-vertical-center">{renderStep()}</div>;
}

export default Login;

Login.getLayout = OnboardingLayout;
