import { Icon24ErrorCircle } from '@vkontakte/icons';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export type AlertBannerType = 'error' | 'success';

interface IAlertBannerProps {
  message: string;
  type: AlertBannerType;
  hideIcon?: boolean;
  className?: string;
}

function AlertBanner({ message, type, hideIcon = false, className }: IAlertBannerProps) {
  const renderIcon = () => {
    if (hideIcon) {
      return null;
    }

    if (type === 'error') {
      return <Icon24ErrorCircle className="mr-4" />;
    }
    if (type === 'success') {
      return <Icon24ErrorCircle />;
    }
    return null;
  };

  return (
    <div
      className={twMerge(clsx('flex items-center rounded-xl px-3 py-4 w-full', {
        'bg-negative text-u-negative': type === 'error',
        'bg-positive text-u-positive': type === 'success',
      }, className))}
    >
      {renderIcon()}
      <h5>{message}</h5>
    </div>
  );
}

export default AlertBanner;
