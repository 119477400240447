import AlertBanner from '@/components/AlertBanner';
import Button, { ButtonType } from '@/components/Button';
import CountdownTimer from '@/components/CountdownTimer';
import Loader from '@/components/Loader';
import VerificationCodeInput from '@/components/VerificationCodeInput';
import AuthService from '@/lib/Auth';
import { firebaseAuth } from '@/lib/Firebase';
import MiscUtils from '@/utils/MiscUtils';
import PhoneUtils from '@/utils/PhoneUtils';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { RecaptchaVerifier } from 'firebase/auth';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

interface IVerifyPhoneProps {
  phoneNumber: string;
}

const RESEND_CODE_INTERVAL = 5;
const VERIFICATION_CODE_LENGTH = 6;

function VerifyPhone({ phoneNumber = '' }: IVerifyPhoneProps) {
  const router = useRouter();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [enableResend, setEnableResend] = useState<boolean>(false);
  const [nextResendDate, setNextResendDate] = useState<Date>(
    dayjs().add(RESEND_CODE_INTERVAL, 'seconds').toDate()
  );

  const mutation = useMutation(AuthService.verifyLoginVerificationCode, {
    onSuccess: async (data) => {
      console.log('CODE VERIFIED SUCCESSFULLY');
      const authToken = await firebaseAuth?.currentUser?.getIdToken(true);
      localStorage.setItem('authToken', authToken as string);
      router.push('/wallet');
    },
  });

  const resendCodeMutation = useMutation(AuthService.sendLoginVerificationCode, {
    onSuccess: (data) => {
      console.log('RESENT VERIFICATION CODE SUCCCESSFULLY');
      setEnableResend(false);
      setNextResendDate(dayjs().add(RESEND_CODE_INTERVAL, 'seconds').toDate());
    },
    onError: (error) => {
      console.log('ERROR RESENDING VERIFICATION CODE', error);
      // if (MiscUtils.isBrowser()) {
      //   window.recaptchaVerifier.render().then(function (widgetId) {
      //     window.recaptchaVerifier.clear();
      //   });
      // }
    },
  });

  const onSubmit = (verificationCode: string) => {
    const code = verificationCode.replace(/[ \(\)]/g, '');
    mutation.mutate({ verificationCode: code });
  };

  const onChange = (code: string) => {
    if (mutation.error) {
      // Clears the error alert at the bottom if there is any
      mutation.reset();
    }
    if (resendCodeMutation.error) {
      // Clears the error alert at the bottom if there is any
      resendCodeMutation.reset();
    }
    setVerificationCode(code);
  };

  const onResendCode = async (e: React.MouseEvent<HTMLButtonElement>) => {
    resendCodeMutation.mutate({ phoneNumber });
  };

  useEffect(() => {
    if (MiscUtils.isBrowser() && enableResend) {
      // @ts-ignore
      window.recaptchaVerifier = new RecaptchaVerifier(
        'resendVerificationCodeBtn',
        {
          size: 'invisible',
          callback: () => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            console.log('RECAPTCHA SOLVED');
          },
        },
        firebaseAuth
      );
    }
  }, [enableResend]);

  const btnDisabled = verificationCode.length !== VERIFICATION_CODE_LENGTH;

  return (
    <div className="flex flex-col justify-center items-center md:w-[496px] h-full">
      <div className="bg-secondary w-full h-[403px] rounded-xl text-center px-14 py-12">
        <h2 className="font-semibold">We sent you a verification code</h2>
        <h2 className="font-semibold mb-8">to {PhoneUtils.sanitizePhoneNumber(phoneNumber)}</h2>
        <form onSubmit={(e) => {
          e.preventDefault();
          onSubmit(verificationCode);
        }}>
          <VerificationCodeInput
            name="verificationCode"
            length={VERIFICATION_CODE_LENGTH}
            className="w-[61px]"
            placeholder="-"
            loading={false}
            onChange={onChange}
            onComplete={onSubmit}
          />
          <Button
            type={ButtonType.Primary}
            buttonType="submit"
            className="w-full mt-8"
            disabled={btnDisabled}
            loading={mutation.isLoading || mutation.isSuccess}
          >
            {btnDisabled ? 'Enter verification code' : 'Continue'}
          </Button>
          <div className="w-full flex justify-center mt-6">
            {enableResend ? (
              resendCodeMutation.isLoading ? (
                <Loader />
              ) : (
                <Button
                  type={ButtonType.Link}
                  buttonType="button"
                  onClick={onResendCode}
                  id="resendVerificationCodeBtn"
                >
                  Resend code
                </Button>
              )
            ) : (
              <h5 className="text-input">
                Resend code (
                <CountdownTimer
                  targetDate={nextResendDate}
                  abbreviatedSuffix
                  expiredMessage="0 seconds"
                  onExpire={async () => setEnableResend(true)}
                />
                )
              </h5>
            )}
          </div>
        </form>
      </div>
      <>
        {(mutation.error || resendCodeMutation.error) && (
          <AlertBanner
            type="error"
            message="We ran into an unexpected issue. Please try again later."
            className="mt-4"
          />
        )}
      </>
    </div>
  );
}

export default VerifyPhone;
