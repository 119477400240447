import AlertBanner from '@/components/AlertBanner';
import Button, { ButtonType } from '@/components/Button';
import Input, { InputHeight, InputStyle } from '@/components/Input';
import AuthService from '@/lib/Auth';
import { firebaseAuth } from '@/lib/Firebase';
import MiscUtils from '@/utils/MiscUtils';
import { useMutation } from '@tanstack/react-query';
import { RecaptchaVerifier } from 'firebase/auth';
import React, { useEffect } from 'react';

interface IEnterPhoneProps {
  onNext: () => void;
  phoneNumber: string;
  onChangePhone: (phone: string) => void;
}

function EnterPhone({ onNext, phoneNumber, onChangePhone }: IEnterPhoneProps) {
  const mutation = useMutation(AuthService.sendLoginVerificationCode, {
    onSuccess: (data) => {
      console.log('SENT VERIFICATION CODE SUCCCESSFULLY');
    },
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutation.mutate({ phoneNumber });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (mutation.error) {
      // Clears the error alert at the bottom if there is any
      mutation.reset();
    }
    onChangePhone(e.target.value);
  };

  useEffect(() => {
    if (MiscUtils.isBrowser()) {
      // @ts-ignore
      window.recaptchaVerifier = new RecaptchaVerifier(
        'loginBtn',
        {
          size: 'invisible',
          callback: () => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            onNext();
          },
        },
        firebaseAuth
      );
    }
  }, []);

  const btnDisabled = !phoneNumber;

  return (
    <div className="flex flex-col justify-center items-center md:w-[496px] h-full">
      <div className="bg-secondary w-full h-[347px] rounded-xl text-center p-8">
        <h2 className="font-semibold">Welcome to Bloom Web.</h2>
        <h2 className="font-semibold mb-9">What is your phone number?</h2>
        <form onSubmit={onSubmit}>
          <Input
            name="phoneNumber"
            type="text"
            inputStyle={InputStyle.Primary}
            inputHeight={InputHeight.Medium}
            value={phoneNumber}
            onChange={onChange}
            autoFocus
            required
            placeholder="(555) 555-5555"
            className="mb-9"
          />
          <Button
            loading={mutation.isLoading || mutation.isSuccess}
            type={ButtonType.Primary}
            buttonType="submit"
            className="w-full"
            disabled={btnDisabled}
            id="loginBtn"
          >
            {btnDisabled ? 'Enter phone number' : 'Continue'}
          </Button>
        </form>
      </div>
      <>
        {mutation.error && (
          <AlertBanner
            type="error"
            message="We ran into an unexpected issue. Please try again later."
            className="mt-4"
          />
        )}
      </>
    </div>
  );
}

export default EnterPhone;
