import Image from 'next/image';

export default function OnboardingHeader() {
  return (
    <header className="w-full pt-11">
      <Image
        src="/images/bloom.png"
        alt="Bloom logo"
        className="left-4 md:left-0 right-0 md:mr-auto md:ml-auto"
        width={170}
        height={48}
        priority
      />
    </header>
  );
}
