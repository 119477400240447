import OnboardingHeader from '@/common/OnboardingHeader';
import { ReactElement } from 'react';

function OnboardingLayout(page: ReactElement) {
  return (
    <div className="bg-gradient-primary w-full">
      <OnboardingHeader />
      <main>{page}</main>
    </div>
  );
}

export default OnboardingLayout;
