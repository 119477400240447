import { firebaseAuth } from '@/lib/Firebase';
import MiscUtils from '@/utils/MiscUtils';
import PhoneUtils from '@/utils/PhoneUtils';
import { ConfirmationResult, signInWithPhoneNumber } from 'firebase/auth';
import { SendLoginVerificationCodeBody, VerifyLoginVerificationCodeBody } from './types';

namespace AuthService {
  /**
   * Attempt to login in a user by sending a verification code to their phone number
   *
   * @param body send verification code body
   */
  export async function sendLoginVerificationCode(body: SendLoginVerificationCodeBody): Promise<ConfirmationResult | undefined> {
    const sanitizedPhoneNumber = PhoneUtils.sanitizePhoneNumber(body.phoneNumber);

    if (MiscUtils.isBrowser()) {
      /* @ts-ignore */
      const confirmationRes = await signInWithPhoneNumber(firebaseAuth, sanitizedPhoneNumber, window.recaptchaVerifier);
      /* @ts-ignore */
      window.confirmationResult = confirmationRes;
      return confirmationRes;
    }
    // await OldAPI.post('/user/send-login-verification-code', body);
  }

  /**
  * Verify login code 
  *
  * @param body verify login code body
  */
  export async function verifyLoginVerificationCode(body: VerifyLoginVerificationCodeBody): Promise<void> {
    if (MiscUtils.isBrowser()) {
      try {
        /* @ts-ignore */
        await window.confirmationResult.confirm(body.verificationCode);
      } catch (err) {
        throw new Error("Invalid verification code");
      }
    }
  }

  /**
   * Logout the current user
   */

  export async function logout(): Promise<void> {
    localStorage.removeItem('authToken');
    firebaseAuth.signOut();
  }
}

export default AuthService;
