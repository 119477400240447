import { AsYouTypeFormatter, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

namespace PhoneUtils {

  // specific to US phone numbers - must be refactored for international support
  export function sanitizePhoneNumber(phoneNumber: string): string {
    if (phoneNumber.startsWith('+1')) {
      phoneNumber = phoneNumber.replace('+1', '');
    }
    return `+1${phoneNumber.replace(/[ \(\)]/g, '')}`;
  }

  export function getCallingCode(regionCode: string) {
    return `+${phoneUtil.getCountryCodeForRegion(regionCode)}`;
  }

  export function getMask(regionCode: string) {
    return removeCallingCodeFromPhone(phoneUtil.format(phoneUtil.getExampleNumber(regionCode), PhoneNumberFormat.INTERNATIONAL), getCallingCode(regionCode)).replaceAll(/\d/g, '9');
  }

  export function parsePhoneNumber(phoneNumber: string, region?: string) {
    try {
      if (region) {
        return phoneUtil.parse(phoneNumber, region);
      }
      return phoneUtil.parse(phoneNumber);
    } catch (e) {
      return false;
    }
  }

  export function isValidNumber(phone: libphonenumber.PhoneNumber) {
    return phoneUtil.isValidNumber(phone);
  }

  export function getRegionCodeForNumber(phone: libphonenumber.PhoneNumber) {
    return phoneUtil.getRegionCodeForNumber(phone);
  }

  export function formatE164Phone(phone: libphonenumber.PhoneNumber) {
    return phoneUtil.format(phone, PhoneNumberFormat.E164);
  }

  export function formatIncompletePhone(phone: string, countryCode: string) {
    const a = new AsYouTypeFormatter(countryCode);
    let formattedPhone = '';
    Array.from(phone.replaceAll(/\D+/g, '')).forEach((c) => {
      formattedPhone = a.inputDigit(c);
    });
    return formattedPhone;
  }

  export function removeCallingCodeFromPhone(phone: string, callingCode: string) {
    return phone.replace(new RegExp(`^\\${callingCode}\s*`), '');
  }
}

export default PhoneUtils;
