import useCountdown from '../../hooks/useCountdown';

interface ICountdownTimerProps {
  targetDate: string | number | Date;
  expiredMessage?: string;
  abbreviatedSuffix?: boolean;
  onExpire?: () => Promise<void>;
}
export default function CountdownTimer({
  targetDate,
  expiredMessage,
  abbreviatedSuffix = false,
  onExpire,
}: ICountdownTimerProps) {
  const [days, hours, minutes, seconds] = useCountdown(targetDate, onExpire);

  if (days + hours + minutes + seconds <= 0) {
    return <>{expiredMessage || 'Expired'}</>;
  }

  return (
    <>
      {days > 0 ? `${days}${abbreviatedSuffix ? 'd' : ' days'}, ` : ''}
      {hours > 0 ? `${hours}${abbreviatedSuffix ? 'h' : ' hours'}, ` : ''}
      {minutes > 0 ? `${minutes}${abbreviatedSuffix ? 'm' : ' minutes'}, ` : ''}
      {seconds > 0 ? `${seconds}${abbreviatedSuffix ? 's' : ' seconds'}` : ''}
    </>
  );
}
